<template>
  <div id="kt_header_mobile" class="kt-header-mobile  kt-header-mobile--fixed " >
    <div class="kt-header-mobile__logo" style="width:100px;">
      <router-link to="/" class="mobile-logo">
        <img class="mw-100" alt="Logo" src="../../assets/logo.png"/>
      </router-link>
    </div>
    <div class="kt-header-mobile__toolbar">
      <div style="margin-right:30px" v-if=" user.role.role === 'registrar'" @click="toggleScanner">
        <i class="la la-qrcode" style="font-size: 2.7rem;color: #fff;"></i>
      </div>
      <button
        class="kt-header-mobile__toggler kt-header-mobile__toggler--left kt-header-mobile__toggler--not-outside"
        :class="{'d-none': user.role.role === 'registrar'}"
        v-on:click="toggleMobileAside"
      >
        <span class="kt-header-mobile__toggler--not-outside"></span>
      </button>
<!--      <button class="kt-header-mobile__toggler"><span></span></button>-->
      <button class="kt-header-mobile__topbar-toggler" v-on:click="toggleMobileHeader"><i class="flaticon-more"></i></button>
    </div>
    <WebQrScanner :show-web-scanner="showWebScanner" @scanned-code="webScanResult" @close="closeScanner()" />
  </div>
</template>

<script>
import WebQrScanner from './WebQrScanner.vue'
export default {
  name: 'MobileHeader',
  components: { WebQrScanner },
  data: () => ({
    showWebScanner: false,

  }),
  methods: {
    toggleMobileHeader() {
        this.$store.dispatch('toggleMobileHeader');
    },
    toggleMobileAside() {
        this.$store.dispatch('toggleMobileAside');
    },
    webScanResult(code) {
      code.then(res => {
        if (res.content.includes('https://a.rte.im/#')) {
          
          if (this.$route.name === 'CheckinRegistration') {
            let id = res.content.match(/[^/]+$/)[0];
            if (Object.keys(this.$route.query).length) {
              this.$router.push({ query: {} }).then(() => {
                this.$router.push({ query: { t: id } });
              });
            } else {
              this.$router.push({ query: { t: id } });
            }
            this.$swal({
              title: this.$t('general.success'),
              type: "success",
              timer: 1000,
              showConfirmButton: false
            })
          } else {
            let localUrl = res.content.replace('https://a.rte.im/#', '')
            if (localUrl !== this.$route.path) {
              this.$router.push({ path: localUrl })
            } else {
              this.$swal({
                title: this.$t('general.success'),
                type: "success",
                timer: 1000,
                showConfirmButton: false,
                onClose: () => {
                  this.closeScanner()
                }
              })
            }
          }
        }
      })
        .catch(err => {
          this.$swal(this.$t("general.error"), err.data.message, "error")
        });
      this.closeScanner();
    },
    toggleScanner() {
      this.showWebScanner = !this.showWebScanner;
    },
    closeScanner() {
      this.showWebScanner = false;
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    },
  }
}
</script>